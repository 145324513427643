import React from 'react';
import { uiService, i18nService } from 'app/services';
import { DialogBody } from '@agroop/ui/lib/Dialog';
import { breakTextNode, breakParagraph } from '@agroop/ui/lib/utils/break';
import farmerImg from '../images/farmer.svg';

export const welcomePopup = () =>
  uiService.showPopup({
    closeable: false,
    title: i18nService.t('popups.welcomeMessage.title'),
    message: (
      <DialogBody imageSrc={farmerImg}>{breakTextNode(i18nService.t('popups.welcomeMessage.message', ''), breakParagraph)}</DialogBody>
    ),
    buttons: [{ name: 'next', text: i18nService.t('buttons.next') }],
  });
