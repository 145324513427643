import { navigate } from '@agroop/router';

export const goToLogin = () => {
  navigate('/login');
};

interface SubscribeOptions {
  name: string;
  countryCode: string;
  phoneNumber: string;
  email: string;
  lang: string;
}

export function subscribe({ name, countryCode, phoneNumber, email, lang }: SubscribeOptions) {
  const listId = '2e76fa904e';
  const apikey = '18bb5f7d8b065e18abeb90d388317eec-us9';

  const mergeFields: Record<string, string> = {
    EMAIL: email,
    NAME: name,
    COUNTRY: countryCode,
    PHONE: phoneNumber,
    MC_LANGUAGE: lang,
  };
  const mf = Object.keys(mergeFields).map(n => `merge_vars[${n}]=${encodeURIComponent(mergeFields[n])}`);
  return fetch(
    `https://us9.api.mailchimp.com/2.0/lists/subscribe.json?apikey=${apikey}&id=${listId}&email[email]=${encodeURIComponent(
      email,
    )}&${mf.join('&')}&double_optin=false&send_welcome=false`,
    { mode: 'no-cors' },
  );
}
