import React from 'react';
import { parseQueryMemo } from '@agroop/common/utils/url';
import { ResetPasswordPage } from 'app/pages/action/resetPassword';

export interface FirebaseQueryOptions {
  oobCode: string;
  apiKey: string;
  mode: 'resetPassword';
  continueUrl: string;
}

export function FirebaseAction() {
  const queryOptions = parseQueryMemo(location.search) as FirebaseQueryOptions;
  const { mode } = queryOptions;

  if (mode === 'resetPassword') return <ResetPasswordPage {...queryOptions} />;

  return <div>invalid actions</div>;
}
