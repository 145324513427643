import React from 'react';
import ReactDom from 'react-dom';
import { useServiceInstance } from 'rc-service';
import { QueryClientProvider } from '@tanstack/react-query';

import { navigate, redir, RouteObject, setBasePath, useRouter } from '@agroop/router';
import { setDefaults, defaultMapErrors } from '@agroop/forms';
import initApi, { ServiceError, RequestParams, Security } from '@agroop/api';
import { AppShellProvider } from '@agroop/app-shell';
import NotFound from '@agroop/ui/lib/errors/NotFound';
import '@agroop/ui/styles/index.scss';
import { registerResizeHandler } from '@agroop/ui/lib/utils/dom';
import ActivityIndicator from '@agroop/ui/lib/ActivityIndicator';

import { CreateEntity } from 'app/pages/CreateEntity/CreateEntity';
import LoginPage from 'app/pages/login';
import ForgotPasswordPage from 'app/pages/forgotPassword';
import AppSelectPage from 'app/pages/appSelect';
import { goToLogin } from 'app/utils/auth';
import { ContinuePage } from 'app/pages/continue';
import { i18nService, authService, uiService } from 'app/services';

import { FirebaseAction } from 'app/pages/action';
import { queryClient } from 'app/reactQuery';
import ConfirmNewAccountPage from './pages/confirmNewAccount';

// on window resize set the --vx variable on document
registerResizeHandler();

initApi({
  applyAuth(p: RequestParams<any>, security: Security) {
    return security.id !== 'JWT'
      ? Promise.resolve(p)
      : authService.verifyToken().then(token => ({
          ...p,
          header: { Authorization: `Bearer ${token}`, ...p.header },
        }));
  },
  processRequest(p: RequestParams<any>): RequestParams<any> {
    return { ...p, header: { 'Accept-Language': i18nService.state.language, ...p.header } };
  },
  handleError(error: Error) {
    if (error instanceof TypeError) {
      uiService.showAlert(i18nService.tr('popups.connectionError'));
    } else if (error instanceof ServiceError) {
      if (error.code === 401) {
        uiService.showAlert(i18nService.tr('popups.unauthenticatedError')).then(goToLogin);
      } else if (error.code === 403) {
        navigate('/unauthorized?reason=forbidden', true);
      } else if (error.code === 500) {
        // todo report error after adding analytics to accounts
        // if (import.meta.env.PROD) userGA.reportError('Api:500', error, false);
      }
    }
  },
});

setDefaults({
  // Load default validations from i18n
  getMessage(id) {
    return i18nService.t(`validations.${id}`) || id;
  },
  // map agroop api errors
  mapErrors(errors, form) {
    return errors?.data?.fields ?? errors?.fields ?? defaultMapErrors(errors, form);
  },
  onValidationFailed(/* fields */) {
    // todo maybe show a popup
  },
  onSubmitError(error) {
    const err = error?.data ?? error ?? {};
    const code = err.status || err.code;
    if (err.message && code >= 400 && code < 500) {
      uiService.showAlert({ title: err.title ?? i18nService.t('invalidParams'), message: err.message });
    }
  },
});

setBasePath(BuildInfo.prefix);

const routes: RouteObject = {
  '/': redir('/login'),
  '/login': LoginPage,
  '/confirmNewAccount': ConfirmNewAccountPage,
  '/forgotPassword': ForgotPasswordPage,
  '/action': FirebaseAction,
  '/app': AppSelectPage,
  '/continue': ContinuePage,
  '/createEntity': CreateEntity,
};

function Main() {
  const match = useRouter(routes);
  const i18nReady = useServiceInstance(i18nService, state => state.i18n.main);
  const authUser = useServiceInstance(authService);

  const ready = i18nReady && authUser.ready;

  return (
    <QueryClientProvider client={queryClient}>
      <AppShellProvider>{!ready ? <ActivityIndicator /> : match || <MainNotFound />}</AppShellProvider>
    </QueryClientProvider>
  );
}

function MainNotFound() {
  return (
    <div style={{ height: '100vh' }}>
      <NotFound title="Not found" message="Place not found" button="Login" onButtonClick={() => navigate('/login')} />
    </div>
  );
}

ReactDom.render(<Main />, document.getElementById('root'));
