import React from 'react';
import BaseFooter from 'app/components/BaseFooter';
import { goToLogin } from 'app/utils/auth';
import { PasswordInput } from 'app/components/PasswordInput';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { firebaseAuth } from '@agroop/common/firebase';
import { useQuery } from '@tanstack/react-query';
import ActivityIndicator from '@agroop/ui/lib/ActivityIndicator';
import { navigate } from '@agroop/router';
import { useService } from 'rc-service';
import BgImage from '@agroop/app-shell/lib/components/BgImage';
import { Button } from '@agroop/ui/lib/Button';
import { Grid, GridCell } from '@agroop/ui/lib/Grid';
import { useForm } from '@agroop/forms';
import { IcArrowBack, IcArrowForward } from '@agroop/icons';
import { I18nService } from '@agroop/common/services';
import { SidebarHeader, useSidebar, UiService } from '@agroop/app-shell';
import { FirebaseQueryOptions } from 'app/pages/action/index';

export const ResetPasswordPage = ({ oobCode, continueUrl }: FirebaseQueryOptions) => {
  const { t, tr } = useService(I18nService);
  const ui = useService(UiService);

  if (!oobCode) return navigate('/login', true);

  const sidebar = useSidebar({ scrim: 'invisible', animated: false, onClose: goToLogin });
  const form = useForm<{ password: string }>({
    onSubmit: fd => {
      confirmPasswordReset(firebaseAuth, oobCode, fd.password)
        .then(() => ui.showAlert(tr('popups.newPassword')))
        .then(() => location.replace(continueUrl));
    },
  });

  const { isError, isLoading } = useQuery(['verifyPasswordResetCode', oobCode], () => verifyPasswordResetCode(firebaseAuth, oobCode), {
    retry: false,
  });

  return (
    <BgImage>
      {sidebar.render(
        <>
          <SidebarHeader closeIcon={<IcArrowBack />} title={t('newPassword.title')} />
          {isLoading ? (
            <ActivityIndicator />
          ) : isError ? (
            <Grid>
              <GridCell span={12} wrap>
                This link that brought you here is no longer valid, it has expired
              </GridCell>
            </Grid>
          ) : (
            <form.Provider>
              <Grid>
                <GridCell span={12} wrap>
                  {t('newPassword.message')}
                </GridCell>
                <GridCell span={12}>
                  <PasswordInput name="password" label={t('main.password')} />
                </GridCell>
                <GridCell span={12} wrap>
                  <Button raised type="submit" disabled={form.submitting} icon={<IcArrowForward />} text={t('newPassword.button')} />
                </GridCell>
              </Grid>
            </form.Provider>
          )}
          <BaseFooter />
        </>,
      )}
    </BgImage>
  );
};
