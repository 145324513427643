import { defaultServiceContext } from 'rc-service';
import { FixedValuesService, I18nService, UserAuthService } from '@agroop/common/services';
import { UiService } from '@agroop/app-shell';

import { getLanguageForNavigator } from '@agroop/common/utils/locale';
import { parseQuery } from '@agroop/common/utils/url';
import { resolveDefault } from '@agroop/common/utils/promise';

const languageLoader = (lang: string, _: string): Promise<any> => import(`./i18n/${lang}.json`).then(resolveDefault);

const query = parseQuery(location.search);

export const i18nService = defaultServiceContext.get(I18nService, null, {
  defaultLanguage: getLanguageForNavigator(query.lang as any),
  features: [{ name: 'main', root: true }],
  languageLoader,
});

export const authService = defaultServiceContext.get(UserAuthService, null);
export const uiService = defaultServiceContext.get(UiService);
export const fixedValuesService = defaultServiceContext.get(FixedValuesService);
