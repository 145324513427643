import React, { useEffect, useState } from 'react';
import { useService } from 'rc-service';

import { useApi } from '@agroop/api/hook';
import { Input, Select } from '@agroop/forms';
import { Button } from '@agroop/ui/lib/Button';
import Checkbox from '@agroop/ui/lib/Checkbox';
import { useForm } from '@agroop/forms/lib/rcform';
import { parseJWT } from '@agroop/common/utils/jwt';
import { Grid, GridCell } from '@agroop/ui/lib/Grid';
import { parseQuery } from '@agroop/common/utils/url';
import { I18nService } from '@agroop/common/services';
import { IcArrowBack, IcArrowForward } from '@agroop/icons';
import { SidebarHeader, useSidebar } from '@agroop/app-shell';
import BgImage from '@agroop/app-shell/lib/components/BgImage';
import { SignupWithEmailAndPasswordRequest } from '@agroop/api/accounts/types';
import { cookiesPolicyUrlMap, privacyPolicyUrlMap, termsAndConditionsUrlMap } from '@agroop/common/utils/languageUrls';

import { fixedValuesService } from 'app/services';
import { upgradePendingUser } from 'app/Firebase';
import BaseFooter from 'app/components/BaseFooter';
import { welcomePopup } from 'app/components/WelcomePopup';
import { PasswordInput } from 'app/components/PasswordInput';

import { ExpiredTokenDialog } from './ExpiredTokenDialog';

const { token } = parseQuery(location.search);

const jwt = token ? parseJWT<{ user: string; parentName: string }>(token) : undefined;

export default function ConfirmNewAccountPage() {
  return jwt && jwt.exp < Date.now() ? <ConfirmNewAccountSidebar /> : <ExpiredTokenDialog entityName={jwt?.parentName} />;
}

function ConfirmNewAccountSidebar() {
  const i18nService = useService(I18nService);
  const { t, tc, state } = i18nService;

  useEffect(() => {
    welcomePopup();
  }, []);

  const form = useForm<SignupWithEmailAndPasswordRequest>({
    onSubmit: upgradePendingUser,
  });

  const [terms, setTerms] = useState(false);

  const countries = useApi(
    () =>
      fixedValuesService.getAllAsync('countries').then(c => {
        const country = c.find(o => o.code === 'PT');
        if (country) form.setValues({ countryCode: country.code, phoneCountryCode: country.code });
        return c;
      }),
    [],
  );

  const sidebar = useSidebar();

  const email = jwt?.sub;
  return (
    <BgImage>
      {sidebar.render(
        <form.Provider>
          <SidebarHeader closeIcon={<IcArrowBack />} title={t('createAccount.title')} />
          <Grid>
            <GridCell>
              <Input name="name" required autoComplete="name" label={t('createAccount.fullName')} />
            </GridCell>
            <GridCell>
              <Select
                name="countryCode"
                required
                label={t('createAccount.country')}
                labelKey="name"
                valueKey="code"
                searchable
                simpleValue
                options={countries}
              />
            </GridCell>
            <GridCell tablet={4} phone={2}>
              <Select
                name="phoneCountryCode"
                required
                label={t('createAccount.phoneIndicative')}
                labelKey="name"
                valueKey="code"
                simpleValue
                renderOption={e => `(+${e.indicative}) ${e.name}`}
                itemToString={e => (e ? `(+${e.indicative})` : '')}
                searchable
                options={countries}
              />
            </GridCell>
            <GridCell tablet={8} phone={4}>
              <Input name="phoneNumber" type="tel" required label={t('createAccount.phoneNumber')} />
            </GridCell>
            <GridCell span={12}>
              <Input
                name="email"
                type="email"
                required
                autoComplete="email"
                label={t('main.email')}
                validations={['email']}
                disabled={!!email}
                defaultValue={email}
              />
            </GridCell>
            <GridCell span={7}>
              <PasswordInput label={t('main.password')} />
            </GridCell>
            <GridCell span={12} wrap>
              <Checkbox
                name="terms"
                checked={terms}
                onChange={e => setTerms(e.currentTarget.checked)}
                label={tc('createAccount.acceptTerms', [
                  <a href={cookiesPolicyUrlMap[state.language]} target="_blank" rel="noreferrer" style={{ fontWeight: 500 }} />,
                  <a href={termsAndConditionsUrlMap[state.language]} target="_blank" rel="noreferrer" style={{ fontWeight: 500 }} />,
                  <a href={privacyPolicyUrlMap[state.language]} target="blank" rel="noreferrer" style={{ fontWeight: 500 }} />,
                ])}
              />
              <Checkbox
                name="newsletter"
                checked={form.formData.newsletter ?? false}
                onChange={e => form.setValue('newsletter', e.currentTarget.checked)}
                label={t('createAccount.subscribeMailingList')}
              />
            </GridCell>
            <GridCell span={12} wrap>
              <Button
                raised
                type="submit"
                disabled={!form.dirty || form.submitting || !terms}
                icon={<IcArrowForward />}
                text={t('createAccount.next')}
              />
            </GridCell>
          </Grid>
          <BaseFooter />
        </form.Provider>,
      )}
    </BgImage>
  );
}
