import React from 'react';
import { Input } from '@agroop/forms';
import { IcVisibility, IcVisibilityOff } from '@agroop/icons';

interface PasswordInputProps {
  name?: string;
  label?: string;
  className?: string;
}

export function PasswordInput({ className, name = 'password', label }: PasswordInputProps) {
  const [on, setOn] = React.useState(true);
  const IconActive = on ? IcVisibilityOff : IcVisibility;
  return (
    <Input
      className={className}
      name={name}
      type={on ? 'password' : 'text'}
      required
      autoComplete="new-password"
      label={label}
      validations={['password']}
      trailingIcon={<IconActive tabIndex={0} role="button" onClick={() => setOn(!on)} />}
    />
  );
}
