import React from 'react';
import type { Placement } from '@popperjs/core';

import { IcInfo, Icon } from '@agroop/icons';
import { Tip } from '@agroop/ui/lib/Tooltip';

interface InfoTipProps {
  className?: string;
  style?: React.CSSProperties;
  message?: string;
  maxWidth?: number;
  placement?: Placement;
}

export function InfoTip({ className, style, message, maxWidth = 350, placement = 'right' }: InfoTipProps) {
  return (
    <Tip message={message} placement={placement} maxWidth={maxWidth}>
      <Icon icon={IcInfo} className={className} style={style} />
    </Tip>
  );
}
