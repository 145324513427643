import type { FirebaseError } from '@firebase/util';
import type { UserCredential } from 'firebase/auth';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithCustomToken,
} from 'firebase/auth';
import {
  signupWithEmailAndPassword,
  signupWithToken,
  validateEmailAndPassword,
  validateFirebaseToken,
} from '@agroop/api/accounts/authentication';
import { navigate } from '@agroop/router';
import { authService, i18nService, uiService } from 'app/services';
import { SignupUserResponse, SignupWithEmailAndPasswordRequest, TApps } from '@agroop/api/accounts/types';
import { firebaseAuth } from '@agroop/common/firebase';
import { backofficeUrl, farmooUrl } from '@agroop/common/utils/app';
import { getUserEntities } from '@agroop/api/farmoo/entities';
import { isSameOrigin, linkTo, parseQuery } from '@agroop/common/utils/url';
import { timeZone } from '@agroop/common/utils/dateTime';
import { gaLogin, gaSignUp } from '@agroop/common/firebase/analytics';
import { LoginFD, CreateAgroopAccount } from './FirebaseTypes';

export const continueWithEmailAndPassword = async ({ mode, email, password }: LoginFD) => {
  if (mode === 'in') return signInWithEmailAndPassword(firebaseAuth, email, password).then(verify, mapErrors);

  await validateEmailAndPassword({ body: { email, password } });
  return navigate('/continue', false, { credentials: { email, password } });
};

export const continueWithGoogle = () => signInWithPopup(firebaseAuth, new GoogleAuthProvider()).then(verify, mapErrors);
export const continueWithFacebook = () => signInWithPopup(firebaseAuth, new FacebookAuthProvider()).then(verify, mapErrors);
export const continueWithApple = () => {
  const provider = new OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  return signInWithPopup(firebaseAuth, provider).then(verify, mapErrors);
};

export const createAgroopAccount = async ({ credentials, currentUser, ...body }: CreateAgroopAccount) => {
  let result: SignupUserResponse;
  // signup

  if (credentials && credentials.email && credentials.password) {
    result = await signupWithEmailAndPassword({ body: { ...body, languageCode: i18nService.state.language, ...credentials } });
    gaSignUp(result.user, 'email');
  } else if (currentUser) {
    const token = await currentUser.getIdToken();
    result = await signupWithToken({ body: { ...body, languageCode: i18nService.state.language, token } });
    gaSignUp(result.user, currentUser.providerId);
  } else return Promise.reject();

  // sign in on firebase with our new user token and do the validation process again
  return signInWithCustomToken(firebaseAuth, result.token).then(verify, mapErrors);
};

export const upgradePendingUser = async (body: SignupWithEmailAndPasswordRequest) => {
  const result = await signupWithEmailAndPassword({ body: { ...body, languageCode: i18nService.state.language, timeZone } });

  const userCredential = await signInWithCustomToken(firebaseAuth, result.token);
  await authService.storeUser(userCredential.user, result.user);

  const { lang, token, redir } = parseQuery<{ lang: string; token: string; redir: string }>(location.search);

  location.href = linkTo(redir, { idToken: result.token, lang, token, userId: result.user.userId });
};

const verify = async (userCredential: UserCredential) => {
  const { redir } = parseQuery(location.search);
  const token = await userCredential.user.getIdToken();

  const createCustomToken = !import.meta.env.PROD || (!!redir && !isSameOrigin(redir));

  const result = await validateFirebaseToken({ token, createCustomToken });
  if (result.user) {
    await authService.storeUser(userCredential.user, result.user);
    gaLogin(result.user, userCredential.providerId ?? 'email');
    if (redir) {
      location.href = linkTo(redir, { idToken: result.token });
    } else if (result.user.apps.length > 1) {
      navigate('/app');
    } else {
      await goToApp(result.user.apps[0]);
    }
  } else {
    navigate('/continue');
  }
};

export async function goToAppUrl(appUrl: string) {
  location.href = `${appUrl}/`;
}

export async function goToApp(app: TApps) {
  if (app === 'FARMOO') {
    const entities = await getUserEntities();

    if (entities.length === 0) navigate('/createEntity');
    else await goToAppUrl(farmooUrl);
  } else if (app === 'BACKOFFICE') {
    await goToAppUrl(backofficeUrl);
  }
}

function mapErrors(e: FirebaseError) {
  if (e.code === 'auth/user-not-found' || e.code === 'auth/wrong-password') {
    const tr = i18nService.tr<{ title: string }>('popups.badCredentials');
    return Promise.reject({
      code: 422,
      ...tr,
      fields: [{ name: 'password', error: tr.title }],
    });
  }
  if (e.code === 'auth/popup-closed-by-user')
    return Promise.reject(uiService.showSnackbar({ message: i18nService.t('popups.socialLoginClosed.message', '') }));
  if (e.code === 'auth/account-exists-with-different-credential')
    return Promise.reject(uiService.showPopup(i18nService.tr('popups.socialLoginOtherCredentials')));
  if (e.code === 'auth/too-many-requests') return Promise.reject(uiService.showPopup(i18nService.tr('popups.tooManyRequests')));

  return e;
}
