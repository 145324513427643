/**
 * Auto-generated, do not edit, it will be overwritten
 * https://github.com/porfirioribeiro/openapi2ts
 */
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { request, ResponsePromise } from '..';
import * as types from './types';

export interface GetIpOptions {
  ip?: string;
}

export const getIp = (options: GetIpOptions): ResponsePromise<types.IpDataResponse, GetIpOptions> =>
  request({
    module: 'accounts',
    method: 'GET',
    uri: '/ip',
    query: { ip: options.ip },
    security: null,
    options,
  });

export const getIpKey = 'getIp';

export const useGetIp = <TData = types.IpDataResponse>(
  options: GetIpOptions,
  queryConfig?: Partial<UseQueryOptions<types.IpDataResponse, unknown, TData>>,
) => useQuery({ queryFn: async () => getIp(options), queryKey: [getIpKey, options.ip], ...queryConfig });
