import React from 'react';
import BgImage from '@agroop/app-shell/lib/components/BgImage/BgImage';
import Dialog, { DialogBody } from '@agroop/ui/lib/Dialog';
import { goToLogin } from '@agroop/common/utils/app';
import { breakTextNode, breakParagraph } from '@agroop/ui/lib/utils/break';
import { useService } from 'rc-service';
import { I18nService } from '@agroop/common/services';
import expiredImg from '../images/expired.svg';

export function ExpiredTokenDialog({ entityName }: { entityName?: string }) {
  const { t } = useService(I18nService);

  return (
    <BgImage full>
      <Dialog
        onButtonClick={goToLogin}
        open
        closeable={false}
        title={entityName ? t('popups.expiredConnection.title') : t('popups.invalidToken.title')}
        buttons={[{ text: t('buttons.login'), name: 'login' }]}
      >
        <DialogBody imageSrc={expiredImg}>
          {breakTextNode(
            entityName ? t('popups.expiredConnection.message', '', { entityName }) : t('popups.invalidToken.message'),
            breakParagraph,
          )}
        </DialogBody>
      </Dialog>
    </BgImage>
  );
}
