import React from 'react';

import { useService } from 'rc-service';

import Checkbox from '@agroop/ui/lib/Checkbox';
import { Button } from '@agroop/ui/lib/Button';
import { useGetIp } from '@agroop/api/accounts/ip';
import { Grid, GridCell } from '@agroop/ui/lib/Grid';
import { I18nService } from '@agroop/common/services';
import { firebaseAuth } from '@agroop/common/firebase';
import { Input, Select, useForm } from '@agroop/forms';
import { Redirect, RouteFnProps } from '@agroop/router';
import { IcArrowBack, IcArrowForward } from '@agroop/icons';
import { SidebarHeader, useSidebar } from '@agroop/app-shell';
import BgImage from '@agroop/app-shell/lib/components/BgImage';
import { countryCode as langCountryCode, timeZone } from '@agroop/common/utils/dateTime';
import { cookiesPolicyUrlMap, privacyPolicyUrlMap, termsAndConditionsUrlMap } from '@agroop/common/utils/languageUrls';
import { themeBg, typographyBodyLarge, typographyBodyMedium, typographyBodySmall } from '@agroop/ui/lib/mdc';

import { fixedValuesService } from 'app/services';
import { goToLogin } from 'app/utils/auth';
import { createAgroopAccount } from 'app/Firebase';
import { CreateAgroopAccount } from 'app/FirebaseTypes';
import BaseFooter from 'app/components/BaseFooter';

import styles from './continue.module.scss';

const loadCountries = () => fixedValuesService.getAllAsync('countries');

export const ContinuePage: React.FC<RouteFnProps> = () => {
  const { currentUser } = firebaseAuth;
  const credentials = history.state?.credentials;

  if (!credentials && !currentUser) return <Redirect to="/login" replace />;

  const { t, tc, state } = useService(I18nService);
  const sidebar = useSidebar({
    scrim: 'invisible',
    animated: false,
    onClose: goToLogin,
    style: { background: '#FFF' },
  });

  const countryCode = useGetIp({}).data?.countryCode ?? langCountryCode;

  const { displayName } = credentials ?? currentUser;

  const form = useForm<CreateAgroopAccount>({
    formData: {
      timeZone,
      countryCode,
      phoneCountryCode: countryCode,
      acceptTerms: false,
      newsletter: false,
      name: displayName ?? '',
      credentials,
      currentUser,
    },
    onSubmit: createAgroopAccount,
  });

  return (
    <BgImage>
      {sidebar.render(
        <form.Provider>
          <SidebarHeader closeIcon={<IcArrowBack />} title={t('createAccount.title')} />

          <Grid className={`${typographyBodyMedium} ${styles.welcome} p-16 ${themeBg}`}>
            <GridCell span={12} wrap>
              <div>{t('createAccount.socialWelcome.welcome')}</div>
              <div>{t('createAccount.socialWelcome.aboutYou')}</div>
            </GridCell>
            <GridCell>
              <div className={`${typographyBodyLarge} ${styles.step}`}>{t('createAccount.step1')}</div>
            </GridCell>
          </Grid>

          <Grid className={styles.grid}>
            <GridCell span={12}>
              <Input name="name" required autoComplete="name" label={t('createAccount.fullName')} />
            </GridCell>
            <GridCell tablet={4} phone={2}>
              <Select
                name="phoneCountryCode"
                required
                label={t('createAccount.phoneIndicative')}
                labelKey="name"
                valueKey="code"
                simpleValue
                renderOption={e => `(+${e.indicative}) ${e.name}`}
                itemToString={e => (e ? `(+${e.indicative})` : '')}
                searchable
                loadOptions={loadCountries}
              />
            </GridCell>
            <GridCell tablet={8} phone={4}>
              <Input name="phoneNumber" type="tel" required label={t('createAccount.phoneNumber')} />
            </GridCell>
            <GridCell span={12} wrap>
              <Checkbox
                name="terms"
                checked={form.formData.acceptTerms}
                onChange={e => form.setValue('acceptTerms', e.currentTarget.checked)}
                label={tc('createAccount.acceptTerms', [
                  <a
                    href={cookiesPolicyUrlMap[state.language]}
                    target="_blank"
                    rel="noreferrer"
                    className={`${typographyBodySmall} ${styles.link}`}
                  />,
                  <a
                    href={termsAndConditionsUrlMap[state.language]}
                    target="_blank"
                    rel="noreferrer"
                    className={`${typographyBodySmall} ${styles.link}`}
                  />,
                  <a
                    href={privacyPolicyUrlMap[state.language]}
                    target="blank"
                    rel="noreferrer"
                    className={`${typographyBodySmall} ${styles.link}`}
                  />,
                ])}
              />
              <Checkbox
                name="newsletter"
                checked={form.formData.newsletter}
                onChange={e => form.setValue('newsletter', e.currentTarget.checked)}
                label={t('createAccount.subscribeMailingList')}
              />
            </GridCell>
            <GridCell span={12} wrap>
              <Button
                raised
                type="submit"
                disabled={!form.dirty || form.submitting || !form.formData.acceptTerms}
                trailingIcon={<IcArrowForward />}
                text={t('createAccount.next')}
              />
            </GridCell>
          </Grid>
          <BaseFooter backgroundColor="#fff" />
        </form.Provider>,
      )}
    </BgImage>
  );
};
