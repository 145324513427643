import React from 'react';
import { useService } from 'rc-service';
import { I18nService } from '@agroop/common/services';
import { locales, languages, TLocale } from '@agroop/common/utils/locale';
import { SidebarFooter } from '@agroop/app-shell';
import { IcArrowDiagonal } from '@agroop/icons';
import { cookiesPolicyUrlMap, privacyPolicyUrlMap, termsAndConditionsUrlMap } from '@agroop/common/utils/languageUrls';

import styles from './BaseFooter.module.scss';

const BaseFooter: React.FC<{ backgroundColor?: string }> = ({ backgroundColor }) => {
  const { t, setLanguage, state } = useService(I18nService);

  return (
    <SidebarFooter position="fixed" className={`${styles.root}`} backgroundColor={backgroundColor}>
      <a target="_blank" rel="noopener noreferrer" href={privacyPolicyUrlMap[state.language]}>
        {t('sharedFooter.privacy')}
        <IcArrowDiagonal />
      </a>
      <a target="_blank" rel="noopener noreferrer" href={termsAndConditionsUrlMap[state.language]}>
        {t('sharedFooter.terms')}
        <IcArrowDiagonal />
      </a>
      <a target="_blank" rel="noopener noreferrer" href={cookiesPolicyUrlMap[state.language]}>
        {t('sharedFooter.cookies')}
        <IcArrowDiagonal />
      </a>
      <select onChange={e => setLanguage(e.target.value as TLocale)} value={state.language} className={styles.select}>
        {languages.map(locale => (
          <option key={locale} value={locale}>
            {locales[locale].name}
          </option>
        ))}
      </select>
    </SidebarFooter>
  );
};

export default BaseFooter;
